.cryotech-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 40px;
    justify-content: center;
    padding: 25px;
    max-width: 100%;
    margin: auto;
    position: relative;
}
.cryotech-title {
    width: 100%;
    text-align: left;
    margin-top: 20px;
    margin-left: 15px;
}
.cryotech-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cryotech-box {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    position: relative;
    cursor: pointer;
}

.cryotech-box:hover, .cryotech-box.selected {
    transform: scale(1.1);
    background: radial-gradient(50% 50% at 49.77% 50%, #FFFFFF 0%, #CBCBCB 100%),
                radial-gradient(50% 50% at 49.77% 50%, #F59F78 0%, #FFDCCC 100%),
                radial-gradient(50% 50% at 49.77% 50%, #EF88E9 0%, #FFF0FE 100%);
}

.cryotech-box.selected {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
                0px 0px 15px 0px rgba(255, 55, 55, 0.11);
}

.cryotech-box.occupied {
    border: 4px solid white; /* Ensures the white border */
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 
                0 0 20px rgba(255, 255, 255, 0.5), 
                0 0 30px rgba(55, 25, 25, 0.3);
    position: relative;
}

.cryotech-name {
    margin-top: 5px;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: 0%;
    text-align: center;
}

.tooltip {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    background: black;
    color: white;
    padding: 8px;
    border-radius: 6px;
    white-space: nowrap;
    font-size: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

/* .cryotech-box:hover .tooltip {
    opacity: 1;
} */

.tooltip-content {
    font-family: Poppins, sans-serif;
    padding: 5px;
    color: white;
}

.tooltip-label {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.4px;
    color: #ccc;
}

.tooltip-value {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: white;
}

.MuiTooltip-tooltip {
    background-color: rgba(4, 0, 0, 0.85);
    border-radius: 6px;
    padding: 8px;
    max-width: 220px;
}
