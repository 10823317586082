.event {
    background-color: #E8EDF4;
    padding: 0;
    border-radius: 0.8rem;
    color: #53719A;
    font-family: Nunito;
    width: 100% !important;
    font-weight: 700;
    text-align: center;
    font-size: 14px;
    width: unset;
    display: block;
}

.event_hm {
    background-color: #F5F5F5;
    padding: 0.2rem 0.3rem;
    border-radius: 0.8rem;
    color: #232323;
    font-family: Nunito;
    width: 100% !important;
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    width: unset;
    display: block;
}


.eventE {
    background-color: rgb(24, 66, 123);
    padding: 0.2rem 0.3rem;
    border-radius: 0.8rem;
    color: white;
    font-family: Nunito;
    width: 100% !important;
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    width: unset;
    display: block;
}

.eventF {
    background-color: rgb(24, 66, 123);
    padding: 0.2rem 0.3rem;
    border-radius: 0.8rem;
    color: white;
    font-family: Nunito;
    width: 100% !important;
    font-weight: 700;
    text-align: center;
    font-size: 12px;
    width: unset;
    display: block;
}

.custHeader {
    height: 60px;
}

.custHeaderc {
    height: 60px;
    background: #fce8e2;
    text-align: center;
}

.headDaycd {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 1.4rem;
    color: #9E9E9E;
    height: 1rem;
    background-color: #ffd396;
}

.headDay {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 1rem;
    color: #9E9E9E;
    height: 1rem;
    margin-bottom: 8px;
}

.dayHeader {
    display: flex;
    width: 100%;
    gap: 0.5rem;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.dayHeader label {
    font-family: Montserrat;
    font-size: 1.2rem;
    font-weight: 600;
}
.dayHeader span {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
}

.camera {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.4rem;
    cursor: pointer;
    height: 1.5rem;
    cursor: pointer;
}

.camera svg {
    height: 30px !important;
    width: 30px !important;
}

.resourceNameP {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 14px;
    color: rgb(70, 70, 70);
}

.resourceNameNP {
    display: block;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    width: 120px;
    height: 2rem;
    color: rgb(70, 70, 70);
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 0.3rem;
    overflow: hidden;
}

.medRes {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.medResClose {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: #e7eae7;
    border-radius: 2rem;
    padding: 0.5rem;
    height: 2rem;
}

.medResClose svg{
    height: 0.8rem !important;
    width: 0.8rem !important;
}

.medRes svg {
    cursor: pointer;
}

.medRes svg.active {
    background-color: #f7f7f7;
    border-radius: 50%;
    box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.5);
}

:root {
    --vh: 1vh; /* Define a custom variable for the viewport height */
}
  

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background-color: #f0f0f0;
    z-index: 9999;
    overflow: hidden;
    opacity: 0; /* Initial opacity */
    transform: scale(0.9); /* Initial scale for a zoom-in effect */
    animation: fadeInScale 0.5s ease-in-out forwards; /* Animation applied */
  }

  .exitFullscreen {
    transform: scale(1); /* Initial scale for a zoom-in effect */
    animation: fadeOutScale 0.5s ease-in-out forwards;
  }
  
  /* Keyframes for the animation */
  @keyframes fadeInScale {
    0% {
      opacity: 0;
      transform: scale(0.6); /* Start slightly smaller */
    }
    100% {
      opacity: 1;
      transform: scale(1); /* End at normal size */
    }
  }

  @keyframes fadeOutScale {
    0% {
      opacity: 0;
      transform: scale(1.4); /* Start slightly smaller */
    }
    100% {
      opacity: 1;
      transform: scale(1); /* End at normal size */
    }
  }