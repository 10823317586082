.container {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
    /* border-radius: 22px;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box; */
}


/* cryoStorage.module.css */
.pointerData {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.82px;
    text-align: left;
    /* text-decoration-line: underline; */
    color: #3a80e2;
  }
  
  .highlight {
    background-color: #e6eef8; 
    color: #3a80e2; 
    border-radius: 50%;
    padding: 1px 6px;
    margin-left: -5px;
  }

  /* Add this to your stylesheet (e.g., styles.css) */
.detailLabel {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #737373;
}

/* Add this to your stylesheet (e.g., styles.css) */
.detailValue {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.82px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #232323;
}
.searchby {
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
  text-align: left;
  color: rgba(35, 35, 35, 1);
  text-wrap: nowrap;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
}
.completeText {
  margin-left: 8px;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.82px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #232323;
}

.stickyFooter {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 10px;
  border-top: 1px solid #ddd;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
