.main {
  display: flex;
  flex-direction: column;
  margin: 3rem 1rem 0rem 1rem;
  gap: 2rem;
  background-color: #f8f7f7;
  padding: 0rem 2rem 1rem 2rem;
  border-radius: 2rem;
  animation: expandAnimation 0.1s ease-in-out forwards;
  /* overflow: hidden; */
}

.main img  {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  margin-top: -3rem;
}

.container {
  padding-top: 0.8rem;
  transition: width 0.6s ease-in-out;
}

.container.collapsed {
  width: fit-content;
}

.container.expanded {
  width: -webkit-fill-available;
}

.main .main_pc {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  margin-top: -3.5rem;
}

.main .main_pc_dv  img, .mainClosed .main_pc_dv  img {
  border: 3px solid #E17E61;
}

.primary {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;
  font-weight: 700;
  justify-content: space-between;
}

.primary_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.primary_item span {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Nunito';
  color: rgb(33, 37, 41);
}

.name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 1rem;
  width: fit-content;
}

.name label {
  font-size: 1.5rem;
  /* width: 15rem; */
  text-overflow: clip;
}

.name span {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Nunito';
  color: rgb(33, 37, 41);
}

.patient_profile {
  color: #666;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 26px;
  padding-left: 3rem;
  margin-bottom: 2rem;
  text-decoration: none; 
  display: flex;
  justify-content: space-between;       
}
.patient_profile img{
  padding-right: 3rem;
  cursor: pointer;
}

.secondary {
  padding-left: 4rem;
  grid-area: 3rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.heading {
  color: #666;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.details {
  padding-top:1rem;
  padding-left: 4rem;
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
}

.details_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.details_item span {
  color: var(--Text-Secondary, #bbb);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  justify-content: center;
  padding-bottom: 4px;
}


.details_item label {
  color: var(--Text-Primary, #232323);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;    
}

.divider {
  padding-top: 2rem;
  border-bottom-width: medium;
}

.mainPics{
  display: flex;
  gap: 1rem;
  cursor: pointer;
}
.pics {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
}

.mainClosed {
  animation: collapseAnimation 0.5s ease-in-out forwards;
}

.primary {
  animation: expandAnimationText 1.2s ease-in-out forwards;
}

.primarClosed {
  animation: expandAnimationText 0.5s ease-in-out forwards;
}

.mainClosed, .primaryClosed {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  margin: 0.4rem 1rem;
  background-color: #f8f7f7;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  font-weight: 700;
}
@keyframes expandAnimationText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes collapseAnimationText {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes expandAnimation {
  0% {
    height: 4.5rem;
  }
  100% {
    max-height: fit-content;
  }
}

@keyframes collapseAnimation {
  0% {
    height: 10rem;
  }
  100% {
    height: 4.5rem;
  }
}

@media screen and (max-width: 950px) {
  .main {
      margin-right: 0;
      margin-left: 0;
  }

  .primary {
      gap: 2rem;
  }

  .secondary {
      padding-left: 0.2rem;
  }

  .details {
      padding-left: 1rem;
      gap: 2rem;
  }
}