/* === Dialog Background Blur Effect === */
.embryo-dialog .MuiBackdrop-root {
    backdrop-filter: blur(6px);
  }
  
  /* === Dialog Styling === */
  .embryo-dialog .MuiDialog-paper {
    border-radius: 14px;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out forwards;
    background: #ffffff;
  }
  
  /* === Header (Title) Styling === */
  .dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 16px 20px;
    border-bottom: 1px solid #ddd;
    background: linear-gradient(to right, #f8f9fa, #ffffff);
  }
  
  /* === Embryo Number === */
  .embryo-number {
    color: #34495e;
    font-weight: 700;
  }
  
  /* === Close Button === */
  .close-button {
    color: #555;
    transition: transform 0.2s ease-in-out;
  }
  
  .close-button:hover {
    transform: scale(1.1);
  }
  
  /* === Image Container === */
  .dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #fafafa;
  }
  
  /* === Image Styling === */
  .embryo-image {
    width: 100%;
    max-height: 65vh;
    object-fit: contain;
    border-radius: 12px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease-in-out;
  }
  
  .embryo-image:hover {
    transform: scale(1.02);
  }
  
  /* === Loading Indicator === */
  .loading-indicator {
    color: #5a67d8 !important;
  }
  
  /* === Buttons === */
  .dialog-actions {
    justify-content: center;
    padding: 16px;
  }
  
  .close-btn,
  .zoom-btn {
    background: linear-gradient(135deg, #4a90e2, #5a67d8);
    color: #fff;
    font-weight: 600;
    border-radius: 20px;
    padding: 10px 24px;
    transition: all 0.3s ease-in-out;
  }
  
  .close-btn:hover,
  .zoom-btn:hover {
    background: linear-gradient(135deg, #5a67d8, #4a90e2);
  }
  
  /* === Animations === */
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.95); }
    to { opacity: 1; transform: scale(1); }
  }

  
  