.container {
    width: 100%;
    height: 100%;
    border-radius: 22px;
    background-color: rgba(255, 255, 255, 1);
    padding: 2rem;
    box-sizing: border-box;
}

.patientsListRow {
    width: 100%;
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1%;
    background-color: white;
    gap: 20px;
}

.patientListNameContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
}

.patientsListRow {
    margin-bottom: 20px;
}

.moreIcon .searchFilter {
    cursor: pointer;
}

.patientListName {
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    text-align: left;
    color: rgba(102, 102, 102, 1);
}

.searchby {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    color: rgba(35, 35, 35, 1);
    text-wrap: nowrap;
    cursor: pointer;
}

@media (min-width: 1550px) {
    .mediaQueryCss {
        justify-content: end;
    }
  }


  @media (max-width: 950px) {
    .container {
        width: 100%;
        height: auto;
        border-radius: 0px;
        padding: 0;
        margin: 0;
    }
  }


  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
  }
  .completeText {
    margin-left: 8px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.82px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #232323;
  }