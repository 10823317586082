.tablesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
}

.tableContainer {
  flex: 1 1 48%;
  margin: 16px 0;
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;
  table-layout: fixed;
}

.tableScrollWrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
}

.tableCell {
  border-right: 1px solid rgba(224, 224, 224, 1);
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px; 
}

.tableCell:nth-child(1) { width: 100px; }
.tableCell:nth-child(2) { width: 90px; }
.tableCell:nth-child(3) { width: 90px; }
.tableCell:nth-child(4) { width: 90px; }
.tableCell:nth-child(5) { width: 100px; }
.tableCell:nth-child(6) { width: 100px; }
.tableCell:nth-child(7) { width: 100px; }
.tableCell:nth-child(8) { width: 100px; }
.tableCell:nth-child(9) { width: 90px; }
.tableCell:nth-child(10) { width: 90px; }
.tableCell:nth-child(11) { width: 90px; }
.tableCell:nth-child(12) { width: 90px; }
.tableCell:nth-child(13) { width: 90px; }
.tableCell:nth-child(14) { width: 90px; }
.tableCell:nth-child(15) { width: 90px; }
.tableCell:nth-child(16) { width: 90px; }
.tableCell:nth-child(17) { width: 90px; }
.tableCell:nth-child(18) { width: 100px; }
.tableCell:nth-child(19) { width: 90px; }
.tableCell:nth-child(20) { width: 100px; }
.tableCell:nth-child(21) { width: 100px; }

.header {
  font-family: 'Nunito', sans-serif !important;
  font-size: 14px !important; 
  font-weight: 700 !important;
  line-height: 1.43rem !important;
  text-align: center !important;
  text-decoration-skip-ink: none !important;
  color: #232323 !important;
}

.tableCellWithBorder {
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.tableRow:last-child .tableCell {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.tableRow:last-child .tableCell:last-child {
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.lastTableCell {
  text-align: center;
  vertical-align: middle;
}

.imageCell {
  width: 100px;
  height: auto;
}
/* 
.tableRow {
  &:last-child td {
    border: none;
  }
} */
