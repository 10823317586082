/* Main Container */
.tank-radio-container {
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 12px;
}

/* Tank Item */
.tank-item {
  padding: 8px 12px;
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 8px; /* Adds spacing between tank items */
}


/* Unselected Tank */
.unselected-tank {
  background: #f8f8f8;
  transform: scale(1);
  transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;
}

/* Selected Tank */
/* .selected-tank {
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 12px; 
} */

/* Selected Tank */
.selected-tank {
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 12px; /* Adjust spacing for selected tank */
  width: 90%; /* Decrease width for selected tank */
  margin-left: auto;
  margin-right: auto;
  transition: width 0.3s ease-in-out;
}

/* Tank Label Container */
.tank-label-container {
  display: flex;
  align-items: center;
  width: 100%;
}

/* Tank Name */
.tank-name {
  font-weight: 600;
  font-size: 14px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  transition: color 0.3s ease-in-out;
}

/* Selected Tank Name */
.selected-tank .tank-name {
  color: #000;
}

/* Tank Percentage */
.tank-percentage {
  font-size: 12px;
  color: #2C3930;
  text-align: right;
  min-width: 50px;
}

/* Progress Bar */

/* Fix for Progress Bar Overlapping */
.progress-bar {
  width: 100%;
  height: 6px;
  border-radius: 4px;
  margin-top: 6px; /* Increase space above progress bar */
}

/* Fix for Radio Button Alignment */
.tank-label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px; /* Ensures spacing between elements */
}

/* Green & Red Bar Styles */
.green-bar {
  background-color: #4caf50;
}

.red-bar {
  background-color: #ff3b30;
}

/* Selected Progress Bar */
.selected-bar {
  background-color: #4caf50;
}

/* Custom Radio Button */
.radio-button {
  width: 18px;
  height: 18px;
  border: 2px solid #999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* Selected Radio */
.radio-button.selected {
  border-color: red;
  background-color: red;
  transform: scale(1.2);
}

/* Scrollable Tank List */
.tank-list-container {
  max-height: 320px; /* Adjust based on the number of tanks */
  overflow-y: auto;
  padding-right: 8px;
}

/* Hide Scrollbar for Webkit Browsers */
.tank-list-container::-webkit-scrollbar {
  width: 6px;
}

.tank-list-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}