.tank-select-container {
    padding-right: 1.5rem;
  }
  
  .tank-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
  }
  
  .tank-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 14.63px;
    letter-spacing: 0px;
  }
  
  .tank-progress-text {
    color: #9e9e9e;
    font-size: 0.875rem;
    text-align: right;
  }
  
  .progress-bar-container {
    width: 100%;
  }
  
  .progress-bar {
    height: 6px;
    border-radius: 3px;
    background-color: #f0f0f0;
    position: relative;
  }
  
  .progress-bar .MuiLinearProgress-bar {
    background-color: #4caf50;
    border-radius: 3px;
  }
  